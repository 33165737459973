import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase();

    return (
        <Layout>
          <SEO title={currentPage.title} />
          <div className="container">
            <div className="row">
              <div className="span12">
                <section className="title-section">
                  <h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                </section>
                <div className={`page-${pageClass}`} dangerouslySetInnerHTML={{ __html: currentPage.content }} />
              </div>
            </div>
          </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
